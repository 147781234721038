import React from "react"
import PropTypes from "prop-types"
import cs from "classnames"

export default class Hero extends React.Component {
  static propTypes = {
    image: PropTypes.string.isRequired,
    justifyEnd: PropTypes.bool,
    backgroundPosition: PropTypes.string,
    minHeight: PropTypes.string,
    hideMobile: PropTypes.bool,
  }

  static defaultProps = {
    justifyEnd: true,
    backgroundPosition: "auto 50%",
    minHeight: "50vh",
    hideMobile: true,
  }

  render() {
    return (
      <div
        className={cs("row mb-5", { "justify-content-end": this.props.justifyEnd }, { "d-none d-md-flex": this.props.hideMobile })}
        style={{
          minHeight: this.props.minHeight,
          backgroundImage: `url(${this.props.image})`,
          backgroundSize: "cover",
          backgroundPosition: this.props.backgroundPosition,
        }}
      >
        {this.props.children}
      </div>
    )
  }
}
