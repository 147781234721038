import React from "react"
import PropTypes from "prop-types"

export default class SectionRight extends React.Component {
  static propTypes = {
    image: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    id: PropTypes.string
  }

  render() {
    return (
      <div className={"row my-5 no-gutters"} id={this.props.id}>
        <div className={"d-none d-lg-block col-lg-8 align-self-center"}>
          <img src={this.props.image} alt={this.props.alt} className={"img-fluid"}/>
        </div>
        <div className={"col-lg-4 ml-lg-n5 align-self-center bg-secondary text-center p-5 shadow"}>
          <h2
            style={{
              fontFamily: "Anton",
              fontWeight: 400,
              fontSize: "2rem"
            }}
            className={'text-uppercase'}
          >
            {this.props.title}
          </h2>
          {this.props.children}
        </div>
      </div>
    )
  }
}
