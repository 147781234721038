import React from 'react';
import Layout from '../components/layout';
import DemoFooter from '../components/demoFooter';
import Hero from '../components/hero';
import HeroImage from '../images/cruisin.jpg';
import SectionRight from '../components/sectionRight';

import d1 from '../images/distribution1.jpg';
import d2 from '../images/distribution2.jpg';
import d3 from '../images/distribution3.jpg';
import d4 from '../images/distribution4.jpg';
import SectionLeft from '../components/sectionLeft';
import SEO from '../components/seo';

export default function () {
    return (
        <Layout>
            <SEO
                title={'Distribution Partners'}
                description={'Automotive parts manufacturers and OEMs are swamped with data, including fitment, product details, images, and more. So what’s the solution? That’s right, it’s PSS. We are, simply put, your most efficient source for data. Our unique data service exports all your data across all channels in the correct format, including full PIES & ACES XML or custom spreadsheets. Just update your listing with PSS, and it automatically updates across all channels.'}
            />
            <Hero image={HeroImage} backgroundPosition={'50% center'} justifyEnd={false}>
                <div className={'col col-lg-6 pl-lg-5 align-self-center text-white'}>
                    <h1
                        style={{
                            fontFamily: 'Anton',
                            fontWeight: 400,
                            fontSize: '4rem',
                        }}
                    >
                        PSS WORKS WITH THE NATIONS LEADING POWERSPORTS DISTRIBUTORS
                    </h1>
                </div>
            </Hero>

            <div className={'row justify-content-center my-5 d-none d-md-flex'}>
                <div className={'col col-md-6 text-center border-top border-bottom'}>
                    <h5 style={{lineHeight: '1.9em'}} className={'font-weight-bold'}>
                        Automotive parts manufacturers and OEMs are swamped with data, including fitment, product details, images, and more. So what’s the solution? That’s right, it’s PSS. We are,
                        simply put, your most efficient source for data. Our
                        unique data service exports all your data across all channels in the correct format, including full PIES & ACES XML or custom spreadsheets. Just update your listing with PSS,
                        and it automatically updates across all channels.
                    </h5>
                </div>
            </div>

            <SectionRight image={d1} alt={'Standing Bikes'} title={'OEM Parts & Accessories'} id={'oem-parts'}>
                <p className={'lead my-3'}>PSS works with every major OEM to provide you with the ability to sell genuine OEM parts & accessories.</p>
            </SectionRight>

            <SectionRight image={d3} alt={'Dune Buggy In Sand 2'} title={'Third Party'} id={'third-party'}>
                <p className={'lead my-3'}>PSS Integrates with CDK LightspeedEVO, ShipWorks, EasyPost, and ShippingEasy. At the
                    same time, PSS gives you greater control, cleaner data, and syncs with PayPal and Stripe for payment and market research.</p>
            </SectionRight>

            <SectionLeft image={d4} alt={'Man Giving Thumbs Up'} title={'Aftermarket Distributors Smart Solutions'} id={'aftermarket-distributors'}>
                <p className={'lead my-3'}>
                    PSS works with the nation’s leading powersports distributors. This includes Helmet House, Western Powersports, Tucker Rocky, Parts Unlimited, Sullivans, Bell Helmets, Castle,
                    Automatic, 509, Kimpex, Troy Lee, FXR, and a lot more.
                </p>
            </SectionLeft>


            <div className={'row justify-content-center my-5'} id={'sema'}>
                <div className={'col col-md-6 text-center border-top border-bottom'}>
                    <h1> SEMA DATA CO-OP</h1>

                    <h5 style={{lineHeight: '1.9em'}} className={'font-weight-bold'}>
                        Automotive parts manufacturers and OEMs are swamped with data, including fitment, product details, images, and more. So what’s the solution? That’s right, it’s PSS. We are,
                        simply put, your most efficient source for data. Our
                        unique data service exports all your data across all channels in the correct format, including full PIES & ACES XML or custom spreadsheets. Just update your listing with PSS,
                        and it automatically updates across all
                        channels. </h5>
                </div>
            </div>


            <DemoFooter/>
        </Layout>
    );
}
